<template>
  <div class="coding">
    <!-- coding
    <a href="http://localhost:8080/#/lhUI" target="_blank">lhui</a>
    coding
    <a href="http://localhost:8080/#/codeGenerate" target="_blank">codeGenerate8080</a>
    <a href="http://localhost:8081/#/codeGenerate" target="_blank">codeGenerate8081</a> -->
    <!-- <lh-router-link to="/lhUI" target="_blank">
      <lh-button padding="30px">lhUI</lh-button>
    </lh-router-link>
    <lh-router-link to="/codeGenerate" target="_blank">
      <lh-button padding="30px">codeGenerate</lh-button>
    </lh-router-link> -->
    <link-model to="/lhUI" fontSize="15px" padding="10px">lhUI</link-model>
    <link-model to="/codeGenerate" fontSize="15px" padding="10px">codeGenerate</link-model>
  </div>
</template>

<script>
import LinkModel from '../components/LinkModel.vue'
export default {
  components: { LinkModel },
  name:"Coding"
}
</script>

<style lang="less" scoped>
.coding{
  display: flex;
}
</style>