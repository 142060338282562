<template>
<div class="modelLink">
  <lh-router-link :to="to" target="_blank">
    <lh-button 
    :fontSize="fontSize" 
    color="black" 
    backgroundColor="#F2F2F2" 
    :padding="padding">
      <img :style="{width:imgWidth,height:imgWidth}" :src="getFavicon(to)"/>
      <slot></slot>
    </lh-button>
  </lh-router-link>
</div>
</template>

<script>
import {splitValueUnit} from "../../utils/lhUtils"
export default {
  name:"LinkModel",
  props:{
    to:{
      type:String,
      default:""
    },
    fontSize:{
      type:String,
      default:"20px"
    },
    padding:{
      type:String,
      default:"20px"
    }
  },
  computed:{
    imgWidth:function(){
      let t=splitValueUnit(this.fontSize);
      return t.value*2+t.unit;
    }
  },
  methods:{
    getFavicon:function(url){
      let index=url.indexOf("http");
      if(index<0){
        //let urlObj=this.$router.resolve(url);
        url=window.location.href;
      }
      let suffix="";
      index=url.indexOf(".io/index");
      if(index>=0){
        suffix="/index";
      }
      let strList=url.split("/");
      console.log(strList)
      return strList[0]+"//"+strList[2]+suffix+"/favicon.ico";
    }
  }
}
</script>

<style lang="less" scoped>
.modelLink{
  //display: inline;
  //border: solid;
  margin: 4px;
  img{
    margin:10px;
  }
}
</style>